<template>
<AuthLayout
  :footer="false"
>
<template #content>
  
  <div class="NotFoundPagePatient">
    
    <div class="NotFoundPagePatient__404">
      404
    </div>
    
    <div class="NotFoundPagePatient__body">
      <div class="NotFoundPagePatient__title">
        Not found
      </div>
      <div class="NotFoundPagePatient__subtitle">
        The requested page was not found
      </div>
    </div>
    
    <a-button
      class="ant-btn-huge NotFoundPagePatient__home"
      type="green"
      size="large"
      @click="$router.push({ path: '/patient' })"
    >
      Go to home
    </a-button>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"

export default {
  name: "NotFoundPagePatient",
  components: {
    AuthLayout,
  },
}
</script>

<style lang="scss" scoped>
.NotFoundPagePatient {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  
  &__404 {
    color: $dayBreakBlue10;
    font-weight: 200;
    font-size: 140px;
    line-height: 100px;
  }
  
  &__body {
    text-align: center;
  }
  
  &__title {
    color: $primary10;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  }
  
  &__subtitle {
    color: $primary10;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  &__home {
    width: 165px;
  }
  
}
</style>